<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10 results">
        <div class="results__title grey darken-4 elevation-15 mb-10">
            <v-card-title class="title">
                {{ data.title }}

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            @click.native="reload"
                            color="primary"
                            :loading="isReloading"
                            fab small
                        ><v-icon>fa-sync</v-icon></v-btn>
                    </template>
                    <span>Reload Results</span>
                </v-tooltip>
            </v-card-title>
        </div>

        <v-simple-table dark>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">TYPE</th>
                        <th class="text-left">TOTAL</th>
                        <th class="text-left">ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Impression Pixel
                        </td>
                        <td>
                            {{ data.impressions_counter }}
                        </td>
                        <td>
                            <v-btn
                                elevation="2"
                                @click.native="reset('impression')"
                                color="primary"
                                :loading="isClearing.impression"
                            >Reset Counter</v-btn>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Click Tracker
                        </td>
                        <td>
                            {{ data.clicks_counter }}
                        </td>
                        <td>
                            <v-btn
                                elevation="2"
                                @click.native="reset('click')"
                                color="primary"
                                :loading="isClearing.click"
                            >Reset Counter</v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

    </v-card>
</template>

<script>
    export default {

        name: 'Results',

        props: ['id', 'data'],

        data: () => ({
            loaded: false,
            isClearing: {
                impression: false,
                click: false
            },
            isReloading: false
        }),

        computed: {

            tracker() {
                return this.$store.getters['tracker/data'];
            },

            loading() {
                return this.$store.getters['tracker/loading'];
            }
        },

        methods: {

            reset(type) {
                this.isClearing[type] = true;

                this.$store.dispatch('tracker/clear', {
                    id: this.id,
                    type
                }).then(() => {
                    this.$store.dispatch('tracker/get', this.id).then(() => {
                        this.isClearing[type] = false;
                    }).catch(() => {});
                }).catch(() => {});
            },

            reload() {
                this.isReloading = true;

                this.$store.dispatch('tracker/get', this.id).then(() => {
                    this.isReloading = false;
                }).catch(() => {});
            }
        }
    }
</script>

<style scoped lang="scss">
    .results__title {
        border-radius: 4px;
    }
</style>
