<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="id !== null"
            >Basic Config</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form ref="formBasic" v-model="formBasicValid">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Tracker Title"
                                v-model="inputs.title"
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.start_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.start_date_formatted"
                                        label="Start Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.start_date"
                                    no-title
                                    @input="menu.start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.end_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.end_date_formatted"
                                        label="End Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.end_date"
                                    no-title
                                    @input="menu.end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn text :to="'/dashboard'">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="saveBasic"
                                :loading="isSavingBasic"
                            >{{ id !== null ? 'Save Changes' : 'Save' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import _ from 'lodash';
    import Constants from '@/constants';
    import DateService from '@/services/date.service';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            step: 1,
            dateService: DateService,
            formBasicValid: true,
            baseURL: Constants.APP_URL,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10)
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10))
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            isSavingBasic: false
        }),

        mounted() {
            this.isSavingBasic = false;

            if (this.id !== null) {
                this.setInputValues();
            }
        },

        watch: {

            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            }
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                   if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });
            },

            saveBasic() {
                this.isSavingBasic = true;
                this.$refs.formBasic.validate();

                if (this.formBasicValid) {
                    const action = this.id === null ? 'new' : 'update';
                    const inputs = _.cloneDeep(this.inputs);
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                    const fd = new FormData();
                    fd.append('title', inputs.title);
                    fd.append('start_date', inputs.start_date);
                    fd.append('end_date', inputs.end_date);

                    const data = this.id === null ? fd : {
                        id: this.id,
                        data: fd
                    };

                    this.$store.dispatch('tracker/' + action, data).then(response => {
                        this.$store.dispatch('tracker/get', response.id).then(() => {
                            this.isSavingBasic = false;

                            if (action === 'new') {
                                this.$router.replace({
                                    name: 'tracker',
                                    params: {
                                        id: response.id,
                                        selectedTab: 'edit'
                                    }
                                });
                            }
                        });
                    }).catch(() => {});
                } else {
                    this.isSavingBasic = false;
                }
            }
        }
    }
</script>
