<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-title class="headline">
            Trackers
        </v-card-title>

        <v-simple-table>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>URL</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Impression Pixel</td>
                    <td>{{ endpoint }}/impression</td>
                    <td>
                        <v-btn elevation="2" @click.native="copy('impression')">Copy</v-btn>
                    </td>
                </tr>
                <tr>
                    <td>Click Tracker</td>
                    <td>{{ endpoint }}/click</td>
                    <td>
                        <v-btn elevation="2" @click.native="copy('click')">Copy</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
    import Constants from '@/constants';
    import NotificationService from '@/services/notification.service';

    export default {

        name: 'Api',

        props: ['id', 'data'],

        data: vm => ({
            message: false,
            endpoint: Constants.APP_URL + '/api/v1/trackers/' + vm.data.id,
            doc: 'https://documenter.getpostman.com/view/218445/flow-api-external/6n4Xa2U#30f35da0-8431-8a2d-eea2-48d260efcb67'
        }),

        methods: {

            getUrl(type) {
                return (this.endpoint + '/' + type);
            },

            getLink(type) {
                const url = this.getUrl(type);
                const ip = '<img src="' + url + '?cb=[timestamp]" border="0" height="1" width="1" alt="Advertisement" />';

                return type === 'click' ? url : ip;
            },

            _fallbackCopy(text) {
                const textArea = document.createElement('textarea');
                textArea.value = text;
                textArea.style.top = '0';
                textArea.style.left = '0';
                textArea.style.position = 'fixed';

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    const successful = document.execCommand('copy');
                    const msg = successful ? 'successful' : 'unsuccessful';
                    NotificationService.show('Copying to clipboard was ' + msg + '!');
                } catch (err) {
                    NotificationService.show('Could not copy to clipboard!');
                }

                document.body.removeChild(textArea);
            },

            copy(type) {
                const link = this.getLink(type);

                if (!window.navigator.clipboard) {
                    this._fallbackCopy(link);
                    return;
                }

                window.navigator.clipboard.writeText(link).then(() => {
                    NotificationService.show('Copying to clipboard was successful!');
                }, () => {
                    NotificationService.show('Could not copy to clipboard!');
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .creative__api {

        &-container {
            border-radius: 5px;
            margin: 20px auto;
            overflow: hidden;

            &__text {
                font-size: 12px;
                text-align: left;
                font-family: Consolas;
                color: #d5d5d7;
                letter-spacing: 1px;
                padding: 15px;
                word-wrap: break-word;
            }
        }
    }
</style>
